<template>
    <div>
        <CCard v-if="showContent">
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <div class="info">
                    <p><b>Nombre: </b>{{ this.psychologistFields.name }}</p>
                    <p><b>Apellidos: </b>{{ this.psychologistFields.surname }}</p>
                    <p><b>Nombre de usuario: </b>{{ this.psychologistFields.username }}</p>
                    <p><b>Email: </b>{{ this.psychologistFields.email }}</p>
                </div>
                <div v-if="this.$store.state.isAdmin" class="button-container">
                    <CButton class="btn btn-secondary" style="margin-right:1em;" @click="back()">
                        Volver
                    </CButton>
                    <button v-if="!showForm" class="btn btn-show-form" @click="showEditForm">
                        Editar psicólogo
                    </button>
                </div>
            </CCardBody>
        </CCard>
        <CCard v-if="showForm">
            <CCardHeader>
                {{ formTitle }}
            </CCardHeader>
            <CCardBody>
                <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="psychologistFields" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { updatePsychologist, getPsychologistData, getPsychologistsData } from '@/services/psychologists';
import AbstractForm from '@/components/Forms/AbstractForm.vue';
import { getFormEditPsychologist } from "@/utils/forms/psychologist/form";

export default {
    name: "updatePsychologist",
    components: { AbstractForm },
    data(){
      return{
        id: null,
        psychologists: [],
        title: `Información del piscólogo`,
        formTitle: "Editar un psicólogo",
        showForm: false,
        showContent: true,
        psychologistFields: {
            username: null,
            name: null,
            surname: null,
            email: null
        },
        formContent: getFormEditPsychologist()
      }
    },
    created() {
        this.id = this.$route.params.id;
        if(this.id){
            this.getPsychologist()
            this.getPsychologists();
        } 
    },
    methods: {
        onEmitMethods(event, dataPsychologist) {
            switch (event) {
                case 'edit': this.udpatePsychologistData(dataPsychologist); break;
                case 'cancel': this.restoreContent(); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        /**
         * Función para obtener los datos del psicólogo en cuestión
        */
        getPsychologist() {
            if (!this.id) return;
            let data = getPsychologistData(this.id);
            data.catch((error) => {
                if (error.response && error.response.status === 404){
                    this.$router.push('/404');
                    return
                }
                this.$store.state.errorAlert = { status: true, msg: error.toString() };
            })
            .then((result) => {
                if (localStorage.token == undefined) return;
                this.psychologistFields.name = result.name;
                this.psychologistFields.surname = result.surname;
                this.psychologistFields.username = result.username;
                this.psychologistFields.email = result.email;
            })
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        findPsychologistByField(psychologists, field, value) {
            return psychologists.find(psychologist => psychologist[field].toLowerCase() === value.toLowerCase());
        },
        /**
         * Función en la que se obtienen los psicólogos
         * 
         * @returns {object[]} Los psicólogos obtenidos.
         */
         getPsychologists() {
            getPsychologistsData()
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() };})
                .then((result) => {
                    this.psychologists = result.filter(psychologist => psychologist.id !== Number(this.id));
                })
        },
        /**
         * Método para validar los inputs del formulario
         */
        checkInputs(dataPsychologist) {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const specialCharactersRegex = /^[a-zA-ZáéíóúÁÉÍÓÚ\s]+$/;
            const existingPsychologist = this.findPsychologistByField(this.psychologists, 'name', dataPsychologist.name);
            const existingPsychologistUserName = this.findPsychologistByField(this.psychologists, 'username', dataPsychologist.username);
            const existingPsychologistEmail = this.findPsychologistByField(this.psychologists, 'email', dataPsychologist.email);

            const validationRules = [
                { name: 'Nombre', field: 'name', message: 'Introduzca el nombre (máximo de 30 caracteres).', maxLength: 30 },
                { name: 'Nombre', field: 'name', message: 'El nombre no debe contener caracteres especiales, ni números.', regex: specialCharactersRegex },
                { name: 'Apellidos', field: 'surname', message: 'Introduzca el apellido (máximo de 30 caracteres).', maxLength: 30 },
                { name: 'Apellidos', field: 'surname', message: 'El apellido no debe contener caracteres especiales, ni números.', regex: specialCharactersRegex },
                { name: 'Nombre de usuario' ,field: 'username', message: 'Introduzca el nombre de usuario (máximo de 30 caracteres).', maxLength: 30 },
                { name: 'Nombre de usuario', field: 'username', message: 'El nombre de usuario no debe contener espacios', noSpaces: true },
                { name: 'Email', field: 'email', message: 'Introduzca el email (máximo de 30 caracteres).', maxLength: 30 },
                { name: 'Email', field: 'email', message: 'Introduzca un email válido.', regex: emailRegex },
            ];

            if (existingPsychologist) {
                this.showAlertError(`El nombre "${dataPsychologist.name}" ya existe en los psicólogos.`);
                return false;
            }

            if (existingPsychologistUserName) {
                this.showAlertError(`El nombre de usuario "${dataPsychologist.username}" ya existe en los psicólogos.`);
                return false;
            }

            if (existingPsychologistEmail) {
                this.showAlertError(`El email "${dataPsychologist.email}" ya existe en los psicólogos.`);
                return false;
            }

            for (const rule of validationRules) {
                const value = dataPsychologist[rule.field];
                if (value === undefined || value === null) {
                    this.showAlertError(`El campo ${rule.name} está vacío`);
                    return false;
                }
                if(rule.maxLength && value.length > rule.maxLength){
                    this.showAlertError(`El número de caracteres de ${rule.name} debe ser menor o igual al siguiente valor: ${rule.maxLength}`);
                    return false;
                }
                if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataPsychologist[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        /**
         * Método para poder editar un psicólogo
         */
        udpatePsychologistData(dataPsychologist) {
            if (this.checkInputs(dataPsychologist) == false) return;
            updatePsychologist(dataPsychologist, this.id)
                .then(() => {
                    this.$router.push("/psychologists");
                }).catch((error) => {
                    this.$store.state.errorAlert = { status: true, msg: error.toString() } 
                })
        },
        /**
         * Función para mostrar el alert durante 10 segundos.
         */
        showFailureParameters() {
            this.contAlertParams = 10;
        },
        /**
         * Función para mostrar el formulario para editar el psicólogo
         */
        showEditForm(){
            this.showForm = true;
        },
        /**
         * Función para restaurar el contenido
         */
         restoreContent(){
            this.showContent = true;
            this.showForm = false;
        },
        /**
         * Función para volver atrás
        */
        back() {
            this.$router.push("/psychologists");
        },
    },
};
</script>